.curLoc-enter {
    opacity: 0;
}

.curLoc-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.curLoc-exit {
    opacity: 1;
}

.curLoc-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}