.esdAudio {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: auto !important;
    margin-bottom: 0px !important;
    touch-action: manipulation !important;
}

.esdAudio-loading {
    padding-top: 6.75px !important;
    padding-bottom: 3.75px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-left: auto !important;
    margin-bottom: 0px !important;
    touch-action: manipulation !important;
}