@media screen and (max-width: 575px) {
    .lastStopMetric {
        display: none !important
    }

    .metricsMetric {
        display: none !important
    }

    .ptMetrics {
        display: none !important
    }
}

@media screen and (max-width: 991px) {
    .lastStopMetric {
        display: none !important
    }

    .ptCountdown {
        display: none !important
    }
}

.metricNav-left {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-left: 8px !important;
    margin-bottom: 0px !important;
    touch-action: manipulation !important;
}

.metricNav-right {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-bottom: 0px !important;
    margin-left: 8px !important;
    touch-action: manipulation !important;
}

@media screen and (min-width: 576px) {
    .mobileMetrics {
        display: none !important;
    }
}

@media screen and (max-width: 575px) {
    .mobileMetrics-pt-countdown {
        display: none !important;
    }
}

@media screen and (min-width: 992px) {
    .mobileMetrics-pt-countdown {
        display: none !important;
    }
}

.mobileMetrics {
    margin-top: 0.1rem;
    font-size: 0.8rem;
    margin-bottom: 0rem;
}

.arrivalTimeSpan {
    display: inline-block;
    line-height: 1.5;
    margin-top: 3px;
}

.trackerBaseRow {
    --mdb-gutter-x: 0rem !important;
}

.trackerCol {
    --mdb-gutter-x: 1.5rem !important;
}