.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/** ABOVE IS REACT PREFILL CSS **/

.btn {
  padding: .625rem 1rem .625rem 1rem
}

.modal-dialog {
  max-width: 550px !important;
}

.settingsModalDialog {
  max-width: 700px !important;
}

@media (min-width: 576px) and (max-width: 716px) {
  .settingsModalDialog {
    margin: 1.75rem 0.5rem !important;
  }
}

.centered-textalign {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.tracker-loadbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  max-width: 66%;
}