body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a2a2a !important;
}

@media screen and (max-width: 575px) {
  .card {
    font-size: .925rem !important;
  }
  
  small, .small {
    font-size: 0.8rem !important
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-secondary {
  background-color: #d8e4f5 !important;
}

.btn-secondary:active {
  background-color: #bcc1c8 !important;
}

.btn-secondary:hover, .btn-secondary:focus {
  background-color: #cfd6e1 !important;
}

.card-text {
  margin-bottom: 0px !important;
}

.someClassThatDoesNotExist {
  margin-bottom: 0px !important;
}