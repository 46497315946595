@media screen and (min-height: 385px) {
    .btn {
        padding: .625rem 1rem .625rem 1rem !important;
    }

    #donateButton {
        padding-right: 22px !important;
    }

    #uncenterButton {
        width: 52px !important;
        height: 40px !important;
    }

    #buttonsBase {
        left: 10px;
        bottom: 32px;
    }
}

@media screen and (max-height: 385px) {
    .btn {
        padding: .5rem .75rem .5rem .75rem !important;
    }

    #donateButton {
        padding-right: 19px !important;
    }

    #uncenterButton {
        width: 44px !important;
        height: 36px !important;
    }

    #buttonsBase {
        left: 5px;
        bottom: 32px;
    }
}

@media (max-width: 575px) {
    .list-group-item {
        padding: 0.6rem 1rem !important
    }
}

.bunny-icon-small {
    background-image: url("../../images/bunny2.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 18px;
    width: 18px;
    bottom: -0.2rem;
}

.basket-icon-small {
    background-image: url("../../images/basket2.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 18px;
    width: 18px;
    bottom: -0.2rem;
}

.center-icon {
    background-image : url("../../images/bunnycenter-light.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
}

.center-icon-dark {
    background-image : url("../../images/bunnycenter-dark.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
}

.uncenter-icon {
    background-image: url("../../images/bunnyuncenter-light.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
}

.uncenter-icon-dark {
    background-image: url("../../images/bunnyuncenter-dark.png");
    background-size: cover;
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
}

.small-icon {
    height: 18px;
    width: 18px;
    bottom: -0.2rem;
}

.settings-icon {
    margin-right: 8px;
    position: relative;
    bottom: 0.1rem;
}