@media screen and (max-width: 575px) {
    .shutdown-h2 {
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .shutdown-h2 {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 768px) {
    .shutdown-h2 {
        margin-bottom: 10px;
    }
}